<template>
  <navbar></navbar>
  <div>
    <main class="page-content">
      <div class="account-page-area">
        <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <sideMenu></sideMenu>
            </div>
            <div class="col-lg-9">
              <div class="card">
                <div class="research-container">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="research-by-vin-text">
                          <h2 class="">Research a Vehicle<br />By VIN</h2>
                          <span
                            >Users can access details specifically for vehicles
                            that have been sold through our platform, including
                            the vehicle name, starting price, and the final sold
                            price.
                          </span>

                          <div class="rearchVIN">
                            <Form
                              class="vin-form-ele"
                              @submit="onVinSearch"
                              :validation-schema="schemaVin"
                              v-slot="{ errors }"
                             >
                              <div class="row">
                                <div class="vin-container researchbyvin-form">
                                  <div class="researchbyvin-form">
                                    <Field
                                      name="VIN"
                                      type="text"
                                      placeholder="Enter VIN"
                                      class="form-control"
                                      :class="{ 'is-invalid': errors.VIN }"
                                    />
                                    
                                    <button
                                      class="btn btn-primary btnVinResearch"
                                    >
                                      Research
                                    </button> 
                                    <div class="invalid-feedback pos-war">
                                    {{ errors.VIN }}
                                  </div>                                 
                                  </div>
                                  
                                
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                        <label for="errorVinSearch" class="errorResearchSearch vinSearchErrorMsg" v-if="errorVinSearch">{{errorVinSearch }}</label>
                      </div>
                      <div class="col-md-6">
                        <div>
                          <img
                            src="../../../public/assets/images/ram.png"
                            class="research-img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <h4 class="comm-title researchHeadline">Research Vehicle</h4>
                  <hr />
                  <div class="rearchVIN">
                    <Form
                      @submit="onVinSearch"
                      :validation-schema="schemaVin"
                      v-slot="{ errors }"
                    >
                      <div class="row">
                        <div class="col-md-1 text-center vin-container">
                          <label for="vin" class="vinLabel">VIN</label>
                        </div>
                        <div class="col-md-7 vin-container">
                          <Field
                            name="VIN"
                            type="text"
                            placeholder="Enter VIN"
                            class="form-control"
                            :class="{ 'is-invalid': errors.VIN }"
                          />
                          <div class="invalid-feedback">{{ errors.VIN }}</div>
                        </div>

                        <div class="col-md-3 text-center vin-container">
                          <button class="btn btn-primary btnVinResearch">
                            Research
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div> -->
                  <div class="line-container">
                    <div class="line"></div>
                    <div class="text-center">
                      <span class="or-text">OR</span>
                    </div>
                  </div>
                  <!-- <hr /> -->

                  <div class="researchFilter">
                    <Form
                      @submit="onSubmitResearch"
                      :validation-schema="schemaResearch"
                      v-slot="{ errors }"
                     >
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row">

                            <div class="col-md-6">
                              <div>
                                <label for="modelSlider">Model Years</label>
                                <div class="modelSlider">
                                  <Slider
                                    v-model="yearOfMake.value"
                                    v-bind="yearOfMake"
                                    :min="yearOfMake.min"
                                    :max="yearOfMake.max"
                                  ></Slider>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div>
                                <label for="mileageSlider">Mileage(KM)</label>
                                <div class="mileageSliderslider">
                                  <Slider
                                    v-model="mileage.value"
                                    v-bind="mileage"
                                    :min="mileage.min"
                                    :max="mileage.max"
                                  ></Slider>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="">
                                <label for="make">Make</label>
                                <Field
                                  name="Make"
                                  v-model="selectedMake"
                                  as="select"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors.Make }"
                                  @change="getVehicleModel(selectedMake)"
                                >
                                  <option value="" disabled>Select make</option>
                                  <option
                                    v-for="(brand, index) in VechBrand"
                                    :key="index"
                                    :value="brand.make"
                                  >
                                    {{ brand.make }}
                                  </option>
                                </Field>
                                <div class="invalid-feedback">
                                  {{ errors.Make }}
                                </div>
                              </div>

                            </div>
                            <div class="col-md-6">
                              
                              <label for="make">Model</label>

                              <Field
                                name="Model"
                                as="select"
                                class="form-control"
                                :class="{ 'is-invalid': errors.Model }"
                                @change="getVehicleVariant(selectedModel)"
                                v-model="selectedModel"
                              >
                                <option value="" disabled selected>
                                  Select model
                                </option>
                                <option
                                  v-for="(vhmodel, index) in VechModel"
                                  :key="index"
                                  :value="vhmodel.model"
                                >
                                  {{ vhmodel.model }}
                                </option>
                              </Field>
                              <div class="invalid-feedback">
                                {{ errors.Model }}
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div>
                                <label for="Trim">Trim</label>
                                <Field
                                  name="Trim"
                                  as="select"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors.Trim }"
                                  v-model="selectedVariant"
                                >
                                  <option value="" disabled selected>
                                    Select trim
                                  </option>
                                  <option
                                    v-for="(vhvariant, index) in VechVariant"
                                    :key="index"
                                    :value="vhvariant.variant"
                                  >
                                    {{ vhvariant.variant }}
                                  </option>
                                </Field>
                                <div class="invalid-feedback">
                                  {{ errors.Trim }}
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div>
                                <label>Sort By</label>
                                <Field
                                  name="sortBy"
                                  as="select"
                                  class="form-control"
                                  :class="{ 'is-invalid': errors.sortBy }"
                                  v-model="sortBySelected"
                                >
                                  <option value="" disabled selected>
                                    Select sort by value
                                  </option>
                                  <option
                                    v-for="(sortByList, index) in sortByLists"
                                    :key="index"
                                    :value="sortByList.sortBy"
                                  >
                                    {{ sortByList.sortBy }}
                                  </option>
                                </Field>
                                <div class="invalid-feedback">
                                  {{ errors.sortBy }}
                                </div>

                               
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="text-center btnResearch-container">
                                <button class="btn btn-primary btnResearch">
                                  Research
                                </button>
                              </div>
                            </div>


                          </div>
                        </div>
                        
                        <!-- <div class="col-md-1"></div> -->
                      </div>
                    </Form>
                  </div>
                  <label for="errorVehDetailsSearch" class="errorResearchSearch vehDetailSearchErrorMsg" v-if="errorVehDetailsSearch">{{errorVehDetailsSearch }}</label>

                </div>

                <!-- <div v-if="vehileDetailsList.length === 0  && loadError">
                  <hr />
                  <NotFound></NotFound>
                </div> -->

               

                <div   v-if="vehileDetailsList.length > 0"
                >
                <hr />
                  <div   class="rearchResult-container">
                    <table>
                      <thead>
                        <tr>
                          <th scope="col">Vehicle</th>
                          <th scope="col">Start Price</th>
                          <th scope="col">Won Price</th>
                          <!-- <th scope="col">No of customer purchased</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(vehicle, index) in vehileDetailsList"
                          :key="index"
                        >
                          <td data-label="Vehicle">                          
                              {{ vehicle.vehicleName }}
                          
                          </td>
                          <td data-label="Start Price">
                            $ {{ vehicle.aucStartPrice }}
                          </td>
                          <td data-label="won Price">
                            $ {{ vehicle.aucMinWinAmount }}
                          </td>
                          <!-- <td data-label="Period">03/01/2016 - 03/31/2016</td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import navbar from "@/components/navbar.vue";
// import NotFound from "../../components/modules/NotFound.vue";
import Slider from "@vueform/slider";
import sideMenu from "../../components/sideMenu.vue";
import userService from "@/services/user.service";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "Add Vehicles",
  components: {
    sideMenu,
    Form,
    Field,
    Slider,
    // NotFound,
    navbar,
  },
  data() {
    const schemaVin = Yup.object().shape({
      VIN: Yup.string().required("VIN is required"),
    });
    const schemaResearch =  Yup.object().shape({
  Make: Yup.string().when(['Model', 'Trim', 'sortBy'], {
    is: (Model, Trim, sortBy) => !Model && !Trim && !sortBy,
    then: Yup.string().required('Make is required'),
    otherwise: Yup.string()
  }),
  Model: Yup.string(),
  Trim: Yup.string(),
  sortBy: Yup.string(),
});
    return {
      loadError: false,
      successful: false,
      loading: false,
      message: "",
      vehileDetailsList: [],
      VechVariant: [],
      VechModel: [],
      VechBrand: [],
      selectedMake: "",
      selectedModel: "",
      selectedVariant: "",
      schemaVin,
      schemaResearch,
      currentYear: "",
      sortBySelected:"",
      yearOfMake: {
        value: [1885 , 2025],
        min: 1885,
        max: new Date().getFullYear(),
      },
      mileage: {
        value: [0, 4000000],
        min: 0,
        max: 4000000,
      },

      // format: {
      //   prefix: "$",
      //   decimals: 2,
      // },
        sortByLists :[
          {sortBy:"Last 7 days"},
          {sortBy:"Last 3 months"},
          {sortBy:"Last year"},
      ],
      errorVehDetailsSearch: "",
      errorVinSearch:"",

       

    };
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {},
  created() {
    this.currentYear = new Date().getFullYear();
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));
      if (typeof this.userinfo.customerId != "undefined") {
        this.getVehicleBrand();
      } else {
        this.$router.push("/").catch(() => {
          "wrong";
        });
      }
    }
  },
  methods: {
    getVehicleBrand() {

    this.VechModel =[];
    this.VechVariant =[];
      userService
        .getVehicleBrand()
        .then((response) => {
          this.VechBrand = response.data.Data;
          // console.log(this.VechBrand);
       
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getVehicleModel(make) {
      this.VechModel =[];
      this.VechVariant =[];

      userService
        .getVehicleModel(make)
        .then((response) => {
          this.VechModel = response.data.Data;
          // console.log(this.VechModel);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getVehicleVariant(model) {
      userService
        .getVehicleVariant(model)
        .then((response) => {
          this.VechVariant = response.data.Data;
          // console.log(this.VechVariant);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // getVehicleTypes() {
    //   userService
    //     .getVehicleTypes()
    //     .then((response) => {
    //       this.VechType = response.data.Data;
    //       console.log(this.VechType);
    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     });
    // },

    onVinSearch(values) {
      values.yearOfMakeMin = 0;
      values.yearOfMakeMax = 0;
      values.mileageMin = 0;
      values.mileageMax = 0;
      values.Make = "";
      values.Model = "";
      values.Trim = "";
      values.sortBy  = "";
      let searchDetails = new Array(values);
      this.getVehicleResearchList(searchDetails[0],"vinSearch");

     
    },

    onSubmitResearch(values) {
      values.yearOfMakeMin = this.yearOfMake.value[0];
      values.yearOfMakeMax = this.yearOfMake.value[1];
      values.mileageMin = this.mileage.value[0];
      values.mileageMax = this.mileage.value[1];
      values.VIN = ""; 
      
      
      let searchDetails = new Array(values);
    
  
      this.getVehicleResearchList(searchDetails[0],"detailsSearch");
      
    },

    getVehicleResearchList(searchDetails,searchMode) {
     let searchModeValue = searchMode;
    
      if (searchDetails) {
        userService
          .getVehicleResearchList(searchDetails)
          .then((response) => {
            this.vehileDetailsList = response.data.Data;
            this.message = "";
            this.scrolltoBottomPage();
           
          })
          .catch((e) => {
          
            this.vehileDetailsList = [];
            this.message = e.response.data.Message;
            this.message = "";

            if(searchModeValue == "vinSearch"){             
             this.errorVinSearch ="Information unavailable";
            //  setTimeout(() => {
            //   this.errorVinSearch="";
            // }, 4000);
            }
           else if(searchModeValue == "detailsSearch"){

            this.errorVehDetailsSearch = "Information unavailable";
            // setTimeout(() => {
            //   this.errorVehDetailsSearch="";
            // }, 4000);
            
           }

          });
      }
    },
    scrolltoBottomPage(){
      if(this.vehileDetailsList.length === 0){
        this.loadError = true;
      }else{
        this.loadError = false;
      }
      window.scrollTo({ top: 800, behavior: "smooth" });
    }
  },
};
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.rearchCard label {
  margin-bottom: 2px;
}
.rearchCard .form-group {
  margin-bottom: 18px;
}
.researchHeadline {
  font-size: 25px !important;
  font-weight: 600;
  color: #515151;
  margin-bottom: 0 !important;
  padding: 15px !important;
}

/*  */

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: 0.35em;
}

table th,
table td {
  padding: 0.625em;
  text-align: center;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  background-color: #8fa6ff;
  color: white;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: capitalize;
    margin-right: 10px;
  }

  table td:last-child {
    border-bottom: 0;
  }
}

.rearchResult-container {
  margin: 5px;
}
.line-container {
  position: relative;
  margin: 30px 0px;
}

.line {
  border-top: 1px solid #ccc;
  margin: 10px 0;
}

.or-text {
  background-color: #fff;
  padding: 0 10px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
}

.text-center {
  display: inline-block;
}
.rearchVIN {
  margin: 30px 0px;
}
.errorResearchSearch{
  color: red;
}
</style>
